import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    {
        path: '/goodsorder',
        name: 'goodsorder',
        meta: { title: '商品订单' },
        redirect: '/goodsorder/order',
        component: Main,
        children: [
            {
                path: '/goodsorder/order',
                name: 'goodsorder',
                meta: {
                    title: '订单管理',
                    parentPath: '/goodsorder',
                    icon: 'icon_order_guanli',
                    permission: ['view'],
                    keepAlive: true
                },
                component: () => import('@/views/goods_order/order.vue')
            },
            {
                path: '/goodsorder/order_detail',
                name: 'goodsorder_detail',
                meta: {
                    hidden: true,
                    title: '订单详情',
                    parentPath: '/goodsorder',
                },
                component: () => import('@/views/goods_order/order_detail.vue')
            },
            {
                path: '/goodsorder/exchange_order',
                name: 'exchange_order',
                meta: {
                    title: '积分订单',
                    parentPath: '/goodsorder',
                    icon: 'icon_set_jiaoyi',
                    permission: ['view']
                },
                component: () => import('@/views/goods_order/exchange_order.vue')
            },
            {
                path: '/goodsorder/exchange_order_detail',
                name: 'exchange_order_detail',
                meta: {
                    hidden: true,
                    title: '积分订单详情',
                    parentPath: '/goodsorder',
                    prevPath: '/goodsorder/exchange_order'
                },
                component: () => import('@/views/goods_order/exchange_order_detail.vue')
            },
            {
                path: '/goodsorder/redpacket_order',
                name: 'redpacket_order',
                meta: {
                    title: '红包订单',
                    parentPath: '/goodsorder',
                    icon: 'icon_set_jiaoyi',
                    permission: ['view']
                },
                component: () => import('@/views/goods_order/redpacket_order.vue')
            },
            {
                path: '/goodsorder/redpacket_order_detail',
                name: 'redpacket_order_detail',
                meta: {
                    hidden: true,
                    title: '红包订单详情',
                    parentPath: '/goodsorder',
                    prevPath: '/goodsorder/redpacket_order'
                },
                component: () => import('@/views/goods_order/redpacket_order_detail.vue')
            },
            {
                path: '/goodsorder/business_order_detail',
                name: 'business_order_detail',
                meta: {
                    hidden: true,
                    title: '商户订单详情',
                    parentPath: '/goodsorder',
                },
                component: () => import('@/views/goods_order/business_order_detail.vue')
            },
            {
                path: '/goodsorder/business_order',
                name: 'business_order',
                meta: {
                    title: '商户订单',
                    parentPath: '/goodsorder',
                    icon: 'icon_set_jiaoyi',
                    permission: ['view']
                },
                component: () => import('@/views/goods_order/business_order.vue')
            },
            {
                path: '/goodsorder/business_integral_order',
                name: 'business_integral_order',
                meta: {
                    title: '商城积分订单',
                    parentPath: '/goodsorder',
                    icon: 'icon_set_jiaoyi',
                    permission: ['view']
                },
                component: () => import('@/views/goods_order/business_integral_order.vue')
            },
            {
                path: '/goodsorder/business_integral_order_detail',
                name: 'business_integral_order_detail',
                meta: {
                    hidden: true,
                    title: '商城积分订单详情',
                    parentPath: '/goodsorder',
                    prevPath: '/goodsorder/business_integral_order'
                },
                component: () => import('@/views/goods_order/business_integral_order_detail.vue')
            }
           /*
            {
                path: '/goodsorder/after_sales',
                name: 'after_sales',
                meta: {
                    title: '售后订单',
                    parentPath: '/goodsorder',
                    icon: 'icon_order_shouhou',
                    permission: ['view'],
                    keepAlive: true
                },
                component: () => import('@/views/goods_order/after_sales.vue')
            },
            {
                path: '/goodsorder/after_sales_detail',
                name: 'after_sales_detail',
                meta: {
                    hidden: true,
                    title: '售后订单详情',
                    parentPath: '/goodsorder'
                },
                component: () => import('@/views/goods_order/after_sales_detail.vue')
            },
            {
                path: '/goodsorder/delivery',
                name: 'delivery',
                meta: {
                    icon: 'icon_dianpu_xiangqing',
                    hidden: false,
                    title: '批量发货',
                    parentPath: '/goodsorder'
                },
                component: () => import('@/views/goods_order/delivery.vue')
            }
            */

        ]
    }
]

export default routes
