import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    {
        path: '/goods',
        name: 'goods',
        meta: { title: '商品' },
        redirect: '/goods/lists',
        component: Main,
        children: [
            {
                path: '/goods/lists',
                name: 'goods_lists',
                meta: {
                    title: '商品管理',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view'],
                    keepAlive: true
                },
                component: () => import('@/views/goods/lists.vue')
            },
            {
                path: '/goods/category',
                name: 'goods_category',
                meta: {
                    title: '分类管理',
                    parentPath: '/goods',
                    icon: 'icon_sort',
                    permission: ['view']
                },
                component: () => import('@/views/goods/category.vue')
            },
            {
                path: '/goods/integral_category',
                name: 'integral_category',
                meta: {
                    title: '积分商品分类',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view']
                },
                component: () => import('@/views/goods/integral_category.vue')
            },
            {
                path: '/goods/integral_category_edit',
                name: 'integral_category_edit',
                meta: {
                    hidden: true,
                    title: '新增积分商品分类',
                    parentPath: '/goods',
                    prevPath: '/goods/integral_category'
                },
                component: () => import('@/views/goods/integral_category_edit.vue')
            },
            {
                path: '/goods/integral_goods',
                name: 'integral_goods',
                meta: {
                    title: '积分商品管理',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view']
                },
                component: () => import('@/views/goods/integral_goods.vue')
            },
            {
                path: '/goods/integral_goods_edit',
                name: 'integral_goods_edit',
                meta: {
                    hidden: true,
                    title: '新增积分商品',
                    parentPath: '/goods',
                    prevPath: '/goods/integral_goods'
                },
                component: () => import('@/views/goods/integral_goods_edit.vue')
            },

            {
                path: '/goods/redpacket_category',
                name: 'redpacket_category',
                meta: {
                    title: '红包商品分类',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view']
                },
                component: () => import('@/views/goods/redpacket_category.vue')
            },
            {
                path: '/goods/redpacket_category_edit',
                name: 'redpacket_category_edit',
                meta: {
                    hidden: true,
                    title: '新增红包商品分类',
                    parentPath: '/goods',
                    prevPath: '/goods/redpacket_category'
                },
                component: () => import('@/views/goods/redpacket_category_edit.vue')
            },

            {
                path: '/goods/redpacket_goods',
                name: 'redpacket_goods',
                meta: {
                    title: '红包商品管理',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view']
                },
                component: () => import('@/views/goods/redpacket_goods.vue')
            },
            {
                path: '/goods/redpacket_goods_edit',
                name: 'redpacket_goods_edit',
                meta: {
                    hidden: true,
                    title: '新增红包商品',
                    parentPath: '/goods',
                    prevPath: '/goods/redpacket_goods'
                },
                component: () => import('@/views/goods/redpacket_goods_edit.vue')
            },
            {
                path: '/goods/release',
                name: 'goods_release',
                meta: {
                    hidden: true,
                    title: '新增商品',
                    parentPath: '/goods',
                    prevPath: '/goods/lists'
                },
                component: () => import('@/views/goods/release.vue')
            },
            {
                path: '/goods/category_edit',
                name: 'category_edit',
                meta: {
                    hidden: true,
                    title: '新增分类',
                    parentPath: '/goods',
                    prevPath: '/goods/category'
                },
                component: () => import('@/views/goods/category_edit.vue')
            },
            {
                path: '/goods/goods_stock',
                name: 'goods_stock',
                meta: {
                    title: '商品库存列表',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view']
                },
                component: () => import('@/views/goods/goods_stock.vue')
            },
        ]
    }
]

export default routes
